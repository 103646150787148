import { AlpineComponent } from 'alpinejs';

import { FormFieldInfo, getFormValidationErrors } from './formValidation';

interface NominationFormData {
	$refs: {
		form?: HTMLFormElement;
		errorsContainer?: HTMLDivElement;
	};

	invalidFields: FormFieldInfo[];
	submissionAttempted: boolean;
	isFormValid: boolean;
	isErrorsVisible: boolean;

	checkValid: () => void;
	handleValidation: (e: Event) => void;
}

const nominationForm = (): AlpineComponent<NominationFormData> => {
	return {
		$refs: {},
		invalidFields: [],
		submissionAttempted: false,
		isFormValid: false,

		get isErrorsVisible() {
			return this.submissionAttempted && !this.isFormValid;
		},

		checkValid() {
			const { form } = this.$refs;
			if (!form) return;

			this.invalidFields = getFormValidationErrors(form);
			this.isFormValid = this.invalidFields.length === 0;
		},

		handleValidation(e) {
			this.submissionAttempted = true;
			this.checkValid();

			if (!this.isFormValid) {
				e.stopImmediatePropagation();
			}
		},
	};
};

export default nominationForm;
