import { Scorm2004ErrorCode, ScormReturnCode } from './enums';
import { getScorm2004ErrorDescription, Scorm2004Error } from './errors';
import { ScormAPIOptions, ScormSubmitResponse } from './scorm';
import { Scorm2004RootSchema } from './Scorm2004Schema';

/**
 * Scorm 2004 js API interface
 */
export interface Scorm2004APIInterface {
	Initialize(arg?: string): ScormReturnCode;
	Terminate(arg?: string): ScormReturnCode;
	GetValue(key: string): string;
	SetValue(key: string, value: string): ScormReturnCode;
	Commit(arg?: string): ScormReturnCode;
	GetLastError(): Scorm2004ErrorCode;
	GetErrorString(code: string): string;
	GetDiagnostic(arg?: string | number): string;
}

/**
 * Implementation of the Scorm 2004 js API
 */
export class Scorm2004API implements Scorm2004APIInterface {
	private savePath: string;

	private lastError: Scorm2004ErrorCode;
	private initialised: boolean;
	private terminated: boolean;

	private schemaData: Scorm2004RootSchema;

	private saving: boolean;
	private savePending: boolean;
	private pendingValues: Record<string, string>;

	private onTerminate?: () => void;
	private onSubmitComplete?: (data: ScormSubmitResponse) => void;
	private onModuleCompleted?: () => boolean;

	public debug = false;

	constructor(options: ScormAPIOptions) {
		this.savePath = options.savePath;
		this.lastError = Scorm2004ErrorCode.NO_ERROR;
		this.initialised = false;
		this.terminated = false;
		this.saving = false;
		this.savePending = false;
		this.pendingValues = {};
		this.onTerminate = options.onFinish;
		this.onSubmitComplete = options.onSubmitComplete;
		this.onModuleCompleted = options.onModuleCompleted;

		this.schemaData = new Scorm2004RootSchema(options.data);
	}

	public debugScormData() {
		const record = this.schemaData.serialise();
		console.log(record);
	}

	public Initialize(): ScormReturnCode {
		this.lastError = Scorm2004ErrorCode.NO_ERROR;

		if (this.initialised) {
			if (this.debug) {
				console.log('[DEBUG] Initialize() failed - already initialised');
			}

			this.lastError = Scorm2004ErrorCode.ALREADY_INITIALIZED;
			return ScormReturnCode.FAILURE;
		}

		if (this.debug) {
			console.log('[DEBUG] Initialize()');
		}

		this.initialised = true;
		return ScormReturnCode.SUCCESS;
	}

	public GetValue(key: string): string {
		this.lastError = Scorm2004ErrorCode.NO_ERROR;

		if (!this.initialised) {
			if (this.debug) {
				console.log(`[DEBUG] GetValue("${key}") failed - not initialised`);
			}

			this.lastError = Scorm2004ErrorCode.RETRIEVE_DATA_BEFORE_INITIALIZATION;
			return '';
		}

		if (this.terminated) {
			if (this.debug) {
				console.log(`[DEBUG] GetValue("${key}") failed - terminated`);
			}

			this.lastError = Scorm2004ErrorCode.RETRIEVE_DATA_AFTER_TERMINATION;
			return '';
		}

		try {
			const result = this.schemaData.getValue(key);

			if (this.debug) {
				console.log(`[DEBUG] GetValue("${key}") -> "${result}"`);
			}

			return result;
		} catch (err) {
			if (this.debug) {
				console.log(`[DEBUG] GetValue("${key}") threw error:`, err);
			}

			if (err instanceof Scorm2004Error) {
				this.lastError = err.code;
			} else {
				this.lastError = Scorm2004ErrorCode.GET_FAILURE;
			}
			return '';
		}
	}

	public SetValue(key: string, value: string): ScormReturnCode {
		this.lastError = Scorm2004ErrorCode.NO_ERROR;

		if (!this.initialised) {
			if (this.debug) {
				console.log(`[DEBUG] SetValue("${key}", "${value}") failed - not initialised`);
			}

			this.lastError = Scorm2004ErrorCode.STORE_DATA_BEFORE_INITIALIZATION;
			return ScormReturnCode.FAILURE;
		}

		if (this.terminated) {
			if (this.debug) {
				console.log(`[DEBUG] SetValue("${key}", "${value}") failed - terminated`);
			}

			this.lastError = Scorm2004ErrorCode.STORE_DATA_AFTER_TERMINATION;
			return ScormReturnCode.FAILURE;
		}

		const strValue = `${value}`;

		const currentValue = this.schemaData.getRawValue(key);
		if (currentValue === strValue) {
			if (this.debug) {
				console.log(`[DEBUG] SetValue("${key}", "${value}") - value unchanged`);
			}

			// no change
			return ScormReturnCode.SUCCESS;
		}

		try {
			this.schemaData.setValue(key, strValue);
			this.pendingValues[key] = strValue;

			if (this.debug) {
				console.log(`[DEBUG] SetValue("${key}", "${value}")`);
			}

			if (key === 'cmi.completion_status' && this.onModuleCompleted) {
				const wasPreviouslyCompleted = currentValue === 'completed';
				const isNowCompleted = strValue === 'completed';
				if (!wasPreviouslyCompleted && isNowCompleted) {
					const result = this.onModuleCompleted();
					if (result) {
						this.doSave();
					}
				}
			}
		} catch (err) {
			console.log(err);

			if (this.debug) {
				console.log(`[DEBUG] SetValue("${key}", "${value}") threw error:`, err);
			}

			if (err instanceof Scorm2004Error) {
				this.lastError = err.code;
			} else {
				this.lastError = Scorm2004ErrorCode.SET_FAILURE;
			}
			return ScormReturnCode.FAILURE;
		}

		return ScormReturnCode.SUCCESS;
	}

	public Terminate(): ScormReturnCode {
		this.lastError = Scorm2004ErrorCode.NO_ERROR;

		if (!this.initialised) {
			if (this.debug) {
				console.log('[DEBUG] Terminate() failed - not initialised');
			}

			this.lastError = Scorm2004ErrorCode.TERMINATION_BEFORE_INITIALIZATION;
			return ScormReturnCode.FAILURE;
		}

		if (this.terminated) {
			if (this.debug) {
				console.log('[DEBUG] Terminate() failed - already terminated');
			}

			this.lastError = Scorm2004ErrorCode.TERMINATION_AFTER_TERMINATION;
			return ScormReturnCode.FAILURE;
		}

		if (this.debug) {
			console.log('[DEBUG] Terminate()');
		}

		// call doSave if there are pending values
		if (Object.keys(this.pendingValues).length > 0) {
			this.doSave();
		}

		this.terminated = true;

		// run onTerminate() after Terminate() has returned
		setTimeout(() => {
			if (this.onTerminate) {
				this.onTerminate();
			}
		}, 0);

		return ScormReturnCode.SUCCESS;
	}

	public Commit(): ScormReturnCode {
		if (this.debug) {
			console.log('[DEBUG] Commit()');
		}

		this.doSave();
		return ScormReturnCode.SUCCESS;
	}

	private async doSave(): Promise<boolean> {
		if (this.saving) {
			// wait for the current save to complete, then save
			this.savePending = true;
			return false;
		}

		if (Object.keys(this.pendingValues).length === 0) {
			// nothing to save
			return false;
		}

		this.saving = true;
		this.savePending = false;

		const body = JSON.stringify(this.pendingValues);
		this.pendingValues = {};

		if (this.debug) {
			console.log('[DEBUG] Saving SCORM data to LMS');
		}

		try {
			const response = await fetch(this.savePath, {
				method: 'POST',
				headers: {
					ContentType: 'application/json',
				},
				body,

				// This can be called from a beforeunload event, which would effectively cancel the request
				// as soon as the page is unloaded (e.g. when navigating away or closing the tab). Set keepalive
				// to true so that the browser (hopefully) still completes the request.
				keepalive: true,
			});
			if (!response.ok) {
				throw new Error(`LMS returned error ${response.status}: ${response.statusText}`);
			}

			if (this.onSubmitComplete) {
				const data = await response.json();
				this.onSubmitComplete(data);
			}
		} catch (err) {
			console.log(`Error saving SCORM data to LMS: ${err}`);
		}

		this.saving = false;

		if (this.savePending) {
			this.doSave();
		}

		return true;
	}

	public GetLastError(): Scorm2004ErrorCode {
		if (this.debug) {
			console.log('[DEBUG] GetLastError()');
		}

		return this.lastError;
	}

	public GetErrorString(code: string): string {
		if (this.debug) {
			console.log(`[DEBUG] GetErrorString("${code}")`);
		}

		return getScorm2004ErrorDescription(code);
	}

	public GetDiagnostic(): string {
		if (this.debug) {
			console.log('[DEBUG] GetDiagnostic()');
		}

		return '';
	}

	public isModuleCompleted(): boolean {
		const value = this.schemaData.getRawValue('cmi.completion_status');
		return value === 'completed';
	}
}
