import '@fontsource/poppins/500.css';
import '@fontsource/poppins/700.css';
import '@fontsource-variable/nunito-sans';
import './styles/index.pcss';
import './setupSentry';

import Alpine from 'alpinejs';
import focus from '@alpinejs/focus';
import collapse from '@alpinejs/collapse';
import intersect from '@alpinejs/intersect';
import Tooltip from '@ryangjchandler/alpine-tooltip';
import { roundArrow } from 'tippy.js';
import 'tippy.js/dist/svg-arrow.css';

import {
	counter,
	courseTopic,
	nominationForm,
	passwordInput,
	paymentForm,
	quiz,
	registrationForm,
	scorm,
} from './modules';

import.meta.glob('./assets/**/*', { eager: true, import: 'default' });

Alpine.plugin(focus);
Alpine.plugin(collapse);
Alpine.plugin(intersect);
Alpine.plugin(
	Tooltip.defaultProps({
		theme: 'secondary',
		arrow: roundArrow,
	}),
);

// Register data modules
Alpine.data('counter', counter);
Alpine.data('courseTopic', courseTopic);
Alpine.data('nominationForm', nominationForm);
Alpine.data('passwordInput', passwordInput);
Alpine.data('paymentForm', paymentForm);
Alpine.data('quiz', quiz);
Alpine.data('registrationForm', registrationForm);
Alpine.data('scorm', scorm);

// This is required for AlpineJS dev tools browser extension; can be removed in production
window.Alpine = Alpine;

Alpine.start();
