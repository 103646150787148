const counter = () => {
	return {
		count: 0,
		increment() {
			this.count += 1;
		},
		decrement() {
			this.count -= 1;
		},
	};
};

export default counter;
