const passwordInput = () => {
	return {
		$refs: {} as {
			input: HTMLInputElement;
			button: HTMLButtonElement;
			rules?: HTMLDivElement;
		},
		// These match the default `$character_strength_tests` rules in Silverstripe
		passwordRules: [
			{
				name: 'length',
				regex: /^.{10,}$/,
			},
			{
				name: 'lowercase',
				regex: /[a-z]/,
			},
			{
				name: 'uppercase',
				regex: /[A-Z]/,
			},
			{
				name: 'digits',
				regex: /[0-9]/,
			},
			{
				name: 'punctuation',
				regex: /[^A-Za-z0-9]/,
			},
		],
		init() {
			this.$refs.input.addEventListener('input', this.onPasswordChange.bind(this));
		},
		updateIcon(element: SVGUseElement, name: string) {
			const asset = element.getAttribute('href')!.split('#')[0];
			element.setAttribute('href', `${asset}#${name}`);
		},
		toggleVisibility() {
			const { button, input } = this.$refs;
			const wasVisible = input.type === 'text';

			input.type = wasVisible ? 'password' : 'text';
			const buttonLabel = wasVisible ? 'Show password' : 'Hide password';
			button.setAttribute('aria-label', buttonLabel);
			button.setAttribute('x-tooltip.raw', buttonLabel);
			this.updateIcon(
				button.querySelector('use')!,
				wasVisible ? 'icon-eye-slash' : 'icon-eye',
			);
		},
		onPasswordChange(e: Event) {
			const rulesElement = this.$refs.rules;
			if (!rulesElement) return;

			// Show a form validation message if the password does not meet the minimum requirements
			const input = e.target as HTMLInputElement;
			const isValid = this.passwordRules.every((rule) => rule.regex.test(input.value));
			input.setCustomValidity(
				isValid ? '' : 'Password does not meet the minimum requirements',
			);

			// Update the password rules to reflect the current input value
			this.passwordRules.forEach((rule) => {
				const element = rulesElement.querySelector(`[data-rule="${rule.name}"]`)!;
				const icon = element.querySelector('use')!;
				element.classList.remove(
					'text-text-primary-muted',
					'text-text-success',
					'text-text-error',
				);
				if (input.value === '') {
					this.updateIcon(icon, 'icon-arrow-right');
					element.classList.add('text-text-primary-muted');
					return;
				}

				const isValid = rule.regex.test(input.value);
				this.updateIcon(icon, isValid ? 'icon-check' : 'icon-x-mark');
				element.classList.add(isValid ? 'text-text-success' : 'text-text-error');
			});
		},
	};
};

export default passwordInput;
