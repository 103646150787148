import { Scorm12ErrorCode, Scorm2004ErrorCode } from './enums';

export const getScorm12ErrorDescription = (code: string): string => {
	switch (code) {
		case Scorm12ErrorCode.NO_ERROR:
			return 'No error';
		case Scorm12ErrorCode.GENERAL_ERROR:
			return 'General exception';
		case Scorm12ErrorCode.INVALID_ARGUMENT:
			return 'Invalid argument exception';
		case Scorm12ErrorCode.ELEMENT_CANNOT_HAVE_CHILDREN:
			return 'Element cannot have children';
		case Scorm12ErrorCode.ELEMENT_NOT_AN_ARRAY:
			return 'Element not an array - cannot have count';
		case Scorm12ErrorCode.NOT_INITIALISED:
			return 'Not initialized';
		case Scorm12ErrorCode.NOT_IMPLEMENTED:
			return 'Not implemented error';
		case Scorm12ErrorCode.ELEMENT_IS_KEYWORD:
			return 'Invalid set value, element is a keyword';
		case Scorm12ErrorCode.ELEMENT_IS_READ_ONLY:
			return 'Element is read only';
		case Scorm12ErrorCode.ELEMENT_IS_WRITE_ONLY:
			return 'Element is write only';
		case Scorm12ErrorCode.INCORRECT_DATA_TYPE:
			return 'Incorrect Data Type';
	}
	return 'Unknown error ' + code;
};

export const getScorm2004ErrorDescription = (code: string): string => {
	switch (code) {
		case Scorm2004ErrorCode.NO_ERROR:
			return 'No error';
		case Scorm2004ErrorCode.GENERAL_ERROR:
			return 'General error';
		case Scorm2004ErrorCode.GENERAL_INITIALIZATION_FAILURE:
			return 'General initialization failure';
		case Scorm2004ErrorCode.ALREADY_INITIALIZED:
			return 'Already initialized';
		case Scorm2004ErrorCode.CONTENT_INSTANCE_TERMINATED:
			return 'Content instance terminated';
		case Scorm2004ErrorCode.GENERAL_TERMINATION_FAILURE:
			return 'General termination failure';
		case Scorm2004ErrorCode.TERMINATION_BEFORE_INITIALIZATION:
			return 'Termination before initialization';
		case Scorm2004ErrorCode.TERMINATION_AFTER_TERMINATION:
			return 'Termination after termination';
		case Scorm2004ErrorCode.RETRIEVE_DATA_BEFORE_INITIALIZATION:
			return 'Retrieve data before initialization';
		case Scorm2004ErrorCode.RETRIEVE_DATA_AFTER_TERMINATION:
			return 'Retrieve data after termination';
		case Scorm2004ErrorCode.STORE_DATA_BEFORE_INITIALIZATION:
			return 'Store data before initialization';
		case Scorm2004ErrorCode.STORE_DATA_AFTER_TERMINATION:
			return 'Store data after termination';
		case Scorm2004ErrorCode.COMMIT_BEFORE_INITIALIZATION:
			return 'Commit before initialization';
		case Scorm2004ErrorCode.COMMIT_AFTER_TERMINATION:
			return 'Commit after termination';
		case Scorm2004ErrorCode.GENERAL_ARGUMENT_ERROR:
			return 'General argument error';
		case Scorm2004ErrorCode.GET_FAILURE:
			return 'Get failure';
		case Scorm2004ErrorCode.SET_FAILURE:
			return 'Set failure';
		case Scorm2004ErrorCode.COMMIT_FAILURE:
			return 'Commit failure';
		case Scorm2004ErrorCode.UNDEFINED_ELEMENT:
			return 'Undefined element';
		case Scorm2004ErrorCode.UNIMPLEMENTED_ELEMENT:
			return 'Unimplemented element';
		case Scorm2004ErrorCode.ELEMENT_NOT_INITIALIZED:
			return 'Element not initialized';
		case Scorm2004ErrorCode.ELEMENT_IS_READ_ONLY:
			return 'Element is read only';
		case Scorm2004ErrorCode.ELEMENT_IS_WRITE_ONLY:
			return 'Element is write only';
		case Scorm2004ErrorCode.INCORRECT_DATA_TYPE:
			return 'Incorrect data type';
		case Scorm2004ErrorCode.ELEMENT_VALUE_OUT_OF_RANGE:
			return 'Element value out of range';
		case Scorm2004ErrorCode.DEPENDENCY_NOT_ESTABLISHED:
			return 'Dependency not established';
	}
	return 'Unknown error ' + code;
};

export class Scorm12Error extends Error {
	constructor(
		public readonly code: Scorm12ErrorCode,
		message?: string,
	) {
		super(message ?? getScorm12ErrorDescription(code));
	}
}

export class Scorm2004Error extends Error {
	constructor(
		public readonly code: Scorm2004ErrorCode,
		message?: string,
	) {
		super(message ?? getScorm2004ErrorDescription(code));
	}
}
