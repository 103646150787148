import * as Sentry from '@sentry/browser';

const dsn = import.meta.env.VITE_SENTRY_DSN;
const environment = import.meta.env.VITE_SENTRY_ENVIRONMENT;
if (dsn && environment) {
	// Setup the Sentry SDK
	const release = import.meta.env.VITE_SENTRY_RELEASE;
	const tracesSampleRate = import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE;
	const profilesSampleRate = import.meta.env.VITE_SENTRY_PROFILES_SAMPLE_RATE;
	window.Sentry = Sentry;
	Sentry.init({
		debug: import.meta.env.DEV,
		dsn,
		environment,
		release,
		tracesSampleRate: tracesSampleRate ? Number(tracesSampleRate) : undefined,
		profilesSampleRate: profilesSampleRate ? Number(profilesSampleRate) : undefined,
	});

	// Add some test functions to the window object
	window.testSentry = () => {
		console.log(`
		You can test Sentry with the following commands:
		- testFrontendError()
		- testFrontendPromiseRejection()
		- testFrontendPromiseException()
		- testBackendUncaughtException()
		- testBackendCaughtException()
		`);
	};
	window.testFrontendError = () => {
		console.log('Test breadcrumb');
		setTimeout(() => {
			throw new Error('Test error');
		}, 0);
	};
	window.testFrontendPromiseRejection = async () => {
		console.log('Test breadcrumb');
		await new Promise((_, reject) => {
			reject(new Error('Test promise rejection'));
		});
	};
	window.testFrontendPromiseException = async () => {
		console.log('Test breadcrumb');
		await new Promise(() => {
			throw new Error('Test promise exception');
		});
	};
	window.testBackendUncaughtException = async () => {
		await fetch('/debug/testUncaughtException');
	};
	window.testBackendCaughtException = async () => {
		await fetch('/debug/testCaughtException');
	};
}
