import { Scorm12API, Scorm12APIInterface } from './Scorm12API';
import { Scorm2004API, Scorm2004APIInterface } from './Scorm2004API';

declare global {
	interface Window {
		API?: Scorm12APIInterface;
		API_1484_11?: Scorm2004APIInterface;
	}
}

export enum ScormVersion {
	SCORM_12 = '1.2',
	SCORM_2004_2ED = 'CAM 1.3',
	SCORM_2004_3ED = '2004 3rd Edition',
	SCORM_2004_4ED = '2004 4th Edition',
}

/**
 * Scorm course data returned from the backend.
 * See CoursePageController#getScormCourseData().
 */
export type ScormInitData = {
	ScormVersion: ScormVersion;
	EntryPath: string;
	SavePath: string;
	SCORMData: Record<string, string>;
	DashboardPath: string;
	NextLink: string;
	NextLinkLabel: string;
};

export type ScormSubmitResponse = {
	success: boolean;
	nav?: string;
};

/**
 * Scorm js API options
 */
export type ScormAPIOptions = {
	data: Record<string, string>;
	savePath: string;
	onFinish?: () => void;
	onSubmitComplete?: (data: ScormSubmitResponse) => void;
	onModuleCompleted?: () => boolean;
};

/**
 * Alpine.js data component for Scorm courses.
 */
export const scorm = (data: ScormInitData) => {
	return {
		data,
		isNavigating: false,
		isCompleted: false,
		nextLink: '',

		$refs: {} as {
			nav: HTMLElement;
		},

		init() {
			/**
			 * If the SCORM module provides an "Exit" button that calls the LMSFinish() / Terminate() function,
			 * then we want to navigate back to the user's dashboard in response. However, the module may also
			 * add an onbeforeunload hook that calls the LMSFinish() / Terminate() function when the user navigates
			 * away - and we don't want to override that navigation. So this adds an event listener to detect
			 * when a navigation occurs, and then in our exit callback we only redirect to the dashboard if the
			 * user is not already navigating somewhere else.
			 */
			window.addEventListener('beforeunload', () => {
				this.isNavigating = true;
			});

			const options: ScormAPIOptions = {
				data: data.SCORMData,
				savePath: data.SavePath,
				onFinish: () => this.onExit(),
				onSubmitComplete: (data) => this.onSubmit(data),
				onModuleCompleted: () => this.onModuleCompleted(),
			};

			if (data.ScormVersion === ScormVersion.SCORM_12) {
				const api = new Scorm12API(options);
				window.API = api;
				this.isCompleted = api.isModuleCompleted();
			} else {
				const api = new Scorm2004API(options);
				window.API_1484_11 = api;
				this.isCompleted = api.isModuleCompleted();
			}
		},

		onExit() {
			if (!this.isNavigating) {
				// redirect to the dashboard
				window.location.href = this.data.DashboardPath;
			}
		},

		onSubmit(data: ScormSubmitResponse) {
			if (data.nav && this.$refs.nav) {
				this.$refs.nav.innerHTML = data.nav;
			}
		},

		onModuleCompleted(): boolean {
			this.isCompleted = true;
			return true;
		},
	};
};
