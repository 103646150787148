import {
	isScorm2004AudioCaptioning,
	isScormCredit,
	isScorm2004InteractionType,
	isScorm2004NavRequestValid,
	isScorm2004Status,
	isScorm2004SuccessStatus,
	isScormTimeLimitAction,
	isScormLessonMode,
	Scorm2004DataType,
	Scorm2004ErrorCode,
	ScormPropertyAccess,
} from './enums';
import { Scorm2004Error } from './errors';

interface Scorm2004PropertyBase {
	name: string | RegExp;
	dataType: Scorm2004DataType;
	access: ScormPropertyAccess;
}

interface Scorm2004ConstProperty extends Scorm2004PropertyBase {
	propType: 'const';
	constValue: string;
}

interface Scorm2004CountProperty extends Scorm2004PropertyBase {
	propType: 'count';
}

type Scorm2004Property = Scorm2004PropertyBase | Scorm2004ConstProperty | Scorm2004CountProperty;

const assertNever = (val: never) => {
	throw new Error(`Unexpected value: ${val}`);
};

/**
 * All the valid Scorm 2004 model properties.
 */
const Scorm2004Props: Scorm2004Property[] = [
	{
		name: 'cmi._version',
		dataType: Scorm2004DataType.CharacterString,
		access: ScormPropertyAccess.ReadOnly,
		propType: 'const',
		constValue: '1.0',
	},

	{
		name: 'cmi.comments_from_learner._children',
		dataType: Scorm2004DataType.CharacterString,
		access: ScormPropertyAccess.ReadOnly,
		propType: 'const',
		constValue: 'comment,location,timestamp',
	},
	{
		name: 'cmi.comments_from_learner._count',
		dataType: Scorm2004DataType.Integer,
		access: ScormPropertyAccess.ReadOnly,
		propType: 'count',
	},
	{
		name: /^cmi\.comments_from_learner\.(\d+)\.comment$/,
		dataType: Scorm2004DataType.LocalizedString,
		access: ScormPropertyAccess.ReadWrite,
	},
	{
		name: /^cmi\.comments_from_learner\.(\d+)\.location$/,
		dataType: Scorm2004DataType.CharacterString,
		access: ScormPropertyAccess.ReadWrite,
	},
	{
		name: /^cmi\.comments_from_learner\.(\d+)\.timestamp$/,
		dataType: Scorm2004DataType.Time,
		access: ScormPropertyAccess.ReadWrite,
	},

	{
		name: 'cmi.comments_from_lms._children',
		dataType: Scorm2004DataType.CharacterString,
		access: ScormPropertyAccess.ReadOnly,
		propType: 'const',
		constValue: 'comment,location,timestamp',
	},
	{
		name: 'cmi.comments_from_lms._count',
		dataType: Scorm2004DataType.Integer,
		access: ScormPropertyAccess.ReadOnly,
		propType: 'count',
	},
	{
		name: /^cmi\.comments_from_lms\.(\d+)\.comment$/,
		dataType: Scorm2004DataType.LocalizedString,
		access: ScormPropertyAccess.ReadOnly,
	},
	{
		name: /^cmi\.comments_from_lms\.(\d+)\.location$/,
		dataType: Scorm2004DataType.CharacterString,
		access: ScormPropertyAccess.ReadOnly,
	},
	{
		name: /^cmi\.comments_from_lms\.(\d+)\.timestamp$/,
		dataType: Scorm2004DataType.Time,
		access: ScormPropertyAccess.ReadOnly,
	},

	{
		name: 'cmi.completion_status',
		dataType: Scorm2004DataType.Status,
		access: ScormPropertyAccess.ReadWrite,
	},
	{
		name: 'cmi.completion_threshold',
		dataType: Scorm2004DataType.Real,
		access: ScormPropertyAccess.ReadOnly,
	},

	{
		name: 'cmi.credit',
		dataType: Scorm2004DataType.Credit,
		access: ScormPropertyAccess.ReadOnly,
	},
	{
		name: 'cmi.entry',
		dataType: Scorm2004DataType.CharacterString,
		access: ScormPropertyAccess.ReadOnly,
	},
	{
		name: 'cmi.exit',
		dataType: Scorm2004DataType.CharacterString,
		access: ScormPropertyAccess.WriteOnly,
	},

	{
		name: 'cmi.interactions._children',
		dataType: Scorm2004DataType.CharacterString,
		access: ScormPropertyAccess.ReadOnly,
		propType: 'const',
		constValue:
			'id,type,objectives,timestamp,correct_responses,weighting,learner_response,result,latency,description',
	},
	{
		name: 'cmi.interactions._count',
		dataType: Scorm2004DataType.Integer,
		access: ScormPropertyAccess.ReadOnly,
		propType: 'count',
	},
	{
		name: /^cmi\.interactions\.(\d+)\.id$/,
		dataType: Scorm2004DataType.LongIdentifier,
		access: ScormPropertyAccess.ReadWrite,
	},
	{
		name: /^cmi\.interactions\.(\d+)\.type$/,
		dataType: Scorm2004DataType.InteractionType,
		access: ScormPropertyAccess.ReadWrite,
	},
	{
		name: /^cmi\.interactions\.(\d+)\.objectives\._count$/,
		dataType: Scorm2004DataType.Integer,
		access: ScormPropertyAccess.ReadOnly,
		propType: 'count',
	},
	{
		name: /^cmi\.interactions\.(\d+)\.objectives\.(\d+)\.id$/,
		dataType: Scorm2004DataType.LongIdentifier,
		access: ScormPropertyAccess.ReadWrite,
	},
	{
		name: /^cmi\.interactions\.(\d+)\.timestamp$/,
		dataType: Scorm2004DataType.Time,
		access: ScormPropertyAccess.ReadWrite,
	},
	{
		name: /^cmi\.interactions\.(\d+)\.correct_responses\._count$/,
		dataType: Scorm2004DataType.Integer,
		access: ScormPropertyAccess.ReadOnly,
		propType: 'count',
	},
	{
		name: /^cmi\.interactions\.(\d+)\.correct_responses\.(\d+)\.pattern$/,
		dataType: Scorm2004DataType.CharacterString,
		access: ScormPropertyAccess.ReadWrite,
	},
	{
		name: /^cmi\.interactions\.(\d+)\.weighting$/,
		dataType: Scorm2004DataType.Real,
		access: ScormPropertyAccess.ReadWrite,
	},
	{
		name: /^cmi\.interactions\.(\d+)\.learner_response$/,
		dataType: Scorm2004DataType.CharacterString,
		access: ScormPropertyAccess.ReadWrite,
	},
	{
		name: /^cmi\.interactions\.(\d+)\.result$/,
		dataType: Scorm2004DataType.CharacterString,
		access: ScormPropertyAccess.ReadWrite,
	},
	{
		name: /^cmi\.interactions\.(\d+)\.latency$/,
		dataType: Scorm2004DataType.TimeInterval,
		access: ScormPropertyAccess.ReadWrite,
	},
	{
		name: /^cmi\.interactions\.(\d+)\.description$/,
		dataType: Scorm2004DataType.LocalizedString,
		access: ScormPropertyAccess.ReadWrite,
	},

	{
		name: 'cmi.launch_data',
		dataType: Scorm2004DataType.CharacterString,
		access: ScormPropertyAccess.ReadOnly,
	},

	{
		name: 'cmi.learner_id',
		dataType: Scorm2004DataType.CharacterString,
		access: ScormPropertyAccess.ReadOnly,
	},
	{
		name: 'cmi.learner_name',
		dataType: Scorm2004DataType.LocalizedString,
		access: ScormPropertyAccess.ReadOnly,
	},

	{
		name: 'cmi.learner_preference._children',
		dataType: Scorm2004DataType.CharacterString,
		access: ScormPropertyAccess.ReadOnly,
		propType: 'const',
		constValue: 'audio_level,language,delivery_speed,audio_captioning',
	},
	{
		name: 'cmi.learner_preference.audio_level',
		dataType: Scorm2004DataType.Real,
		access: ScormPropertyAccess.ReadWrite,
	},
	{
		name: 'cmi.learner_preference.language',
		dataType: Scorm2004DataType.CharacterString,
		access: ScormPropertyAccess.ReadWrite,
	},
	{
		name: 'cmi.learner_preference.delivery_speed',
		dataType: Scorm2004DataType.Real,
		access: ScormPropertyAccess.ReadWrite,
	},
	{
		name: 'cmi.learner_preference.audio_captioning',
		dataType: Scorm2004DataType.AudioCaptioning,
		access: ScormPropertyAccess.ReadWrite,
	},

	{
		name: 'cmi.location',
		dataType: Scorm2004DataType.CharacterString,
		access: ScormPropertyAccess.ReadWrite,
	},

	{
		name: 'cmi.max_time_allowed',
		dataType: Scorm2004DataType.Time,
		access: ScormPropertyAccess.ReadOnly,
	},
	{
		name: 'cmi.mode',
		dataType: Scorm2004DataType.LessonMode,
		access: ScormPropertyAccess.ReadOnly,
	},

	{
		name: 'cmi.objectives._children',
		dataType: Scorm2004DataType.CharacterString,
		access: ScormPropertyAccess.ReadOnly,
		propType: 'const',
		constValue: 'id,score,success_status,completion_status,progress_measure,description',
	},
	{
		name: 'cmi.objectives._count',
		dataType: Scorm2004DataType.Integer,
		access: ScormPropertyAccess.ReadOnly,
		propType: 'count',
	},
	{
		name: /^cmi\.objectives\.(\d+)\.id$/,
		dataType: Scorm2004DataType.LongIdentifier,
		access: ScormPropertyAccess.ReadWrite,
	},
	{
		name: /^cmi\.objectives\.(\d+)\.score\._children$/,
		dataType: Scorm2004DataType.CharacterString,
		access: ScormPropertyAccess.ReadOnly,
		propType: 'const',
		constValue: 'scaled,raw,min,max',
	},
	{
		name: /^cmi\.objectives\.(\d+)\.score\.scaled$/,
		dataType: Scorm2004DataType.Real,
		access: ScormPropertyAccess.ReadWrite,
	},
	{
		name: /^cmi\.objectives\.(\d+)\.score\.raw$/,
		dataType: Scorm2004DataType.Real,
		access: ScormPropertyAccess.ReadWrite,
	},
	{
		name: /^cmi\.objectives\.(\d+)\.score\.min$/,
		dataType: Scorm2004DataType.Real,
		access: ScormPropertyAccess.ReadWrite,
	},
	{
		name: /^cmi\.objectives\.(\d+)\.score\.max$/,
		dataType: Scorm2004DataType.Real,
		access: ScormPropertyAccess.ReadWrite,
	},
	{
		name: /^cmi\.objectives\.(\d+)\.success_status$/,
		dataType: Scorm2004DataType.SuccessStatus,
		access: ScormPropertyAccess.ReadWrite,
	},
	{
		name: /^cmi\.objectives\.(\d+)\.completion_status$/,
		dataType: Scorm2004DataType.Status,
		access: ScormPropertyAccess.ReadWrite,
	},
	{
		name: /^cmi\.objectives\.(\d+)\.progress_measure$/,
		dataType: Scorm2004DataType.Real,
		access: ScormPropertyAccess.ReadWrite,
	},
	{
		name: /^cmi\.objectives\.(\d+)\.description$/,
		dataType: Scorm2004DataType.LocalizedString,
		access: ScormPropertyAccess.ReadWrite,
	},

	{
		name: 'cmi.progress_measure',
		dataType: Scorm2004DataType.Real,
		access: ScormPropertyAccess.ReadWrite,
	},
	{
		name: 'cmi.scaled_passing_score',
		dataType: Scorm2004DataType.Real,
		access: ScormPropertyAccess.ReadOnly,
	},

	{
		name: 'cmi.score._children',
		dataType: Scorm2004DataType.CharacterString,
		access: ScormPropertyAccess.ReadOnly,
		propType: 'const',
		constValue: 'scaled,raw,min,max',
	},
	{
		name: 'cmi.score.scaled',
		dataType: Scorm2004DataType.Real,
		access: ScormPropertyAccess.ReadWrite,
	},
	{
		name: 'cmi.score.raw',
		dataType: Scorm2004DataType.Real,
		access: ScormPropertyAccess.ReadWrite,
	},
	{
		name: 'cmi.score.min',
		dataType: Scorm2004DataType.Real,
		access: ScormPropertyAccess.ReadWrite,
	},
	{
		name: 'cmi.score.max',
		dataType: Scorm2004DataType.Real,
		access: ScormPropertyAccess.ReadWrite,
	},

	{
		name: 'cmi.session_time',
		dataType: Scorm2004DataType.TimeInterval,
		access: ScormPropertyAccess.WriteOnly,
	},

	{
		name: 'cmi.success_status',
		dataType: Scorm2004DataType.SuccessStatus,
		access: ScormPropertyAccess.ReadWrite,
	},

	{
		name: 'cmi.suspend_data',
		dataType: Scorm2004DataType.CharacterString,
		access: ScormPropertyAccess.ReadWrite,
	},

	{
		name: 'cmi.time_limit_action',
		dataType: Scorm2004DataType.TimeLimitAction,
		access: ScormPropertyAccess.ReadOnly,
	},

	{
		name: 'cmi.total_time',
		dataType: Scorm2004DataType.TimeInterval,
		access: ScormPropertyAccess.ReadOnly,
	},

	{
		name: 'adl.nav.request',
		dataType: Scorm2004DataType.CharacterString,
		access: ScormPropertyAccess.ReadWrite,
	},
	{
		name: 'adl.nav.request_valid.continue',
		dataType: Scorm2004DataType.NavRequestValid,
		access: ScormPropertyAccess.ReadOnly,
	},
	{
		name: 'adl.nav.request_valid.previous',
		dataType: Scorm2004DataType.NavRequestValid,
		access: ScormPropertyAccess.ReadOnly,
	},
	{
		name: /^adl\.nav\.request_valid\.choice\.\{target=(.+)\}$/,
		dataType: Scorm2004DataType.NavRequestValid,
		access: ScormPropertyAccess.ReadOnly,
	},
];

const findPropForKey = (key: string): Scorm2004Property | undefined => {
	for (const prop of Scorm2004Props) {
		// does this prop match the key?
		if (prop.name === key) {
			return prop;
		}

		if (typeof prop.name === 'object') {
			// regex
			if (key.match(prop.name)) {
				return prop;
			}
		}
	}

	return undefined;
};

/**
 * Scorm 2004 data model schema.
 * This class contains the data values set by the LMS or the SCO, and provides access to
 * get and set values as appropriate. This handles validation of properties, data types
 * and access restrictions.
 */
export class Scorm2004RootSchema {
	private data: Map<string, string>;

	/**
	 * Create an instance of this class and initialise the model with data from the LMS
	 */
	constructor(lmsData: Record<string, string>) {
		this.data = new Map();
		for (const key in lmsData) {
			this.data.set(key, lmsData[key]);
		}
	}

	private validateDataType(type: Scorm2004DataType, value: string): boolean {
		switch (type) {
			case Scorm2004DataType.CharacterString: {
				return true;
			}
			case Scorm2004DataType.LocalizedString: {
				return true;
			}
			case Scorm2004DataType.LongIdentifier: {
				return value.trim() !== '';
			}
			case Scorm2004DataType.ShortIdentifier: {
				return value.trim() !== '';
			}
			case Scorm2004DataType.Integer: {
				if (!value.match(/^-?\d+$/)) {
					return false;
				}
				return true;
			}
			case Scorm2004DataType.Real: {
				const nbr = Number(value);
				if (Number.isNaN(nbr)) return false;
				return true;
			}
			case Scorm2004DataType.Time: {
				return !!value.match(
					/^\d{4}(-\d{2}(-\d{2}(T\d{2}(:\d{2}(:\d{2}(\.\d{1,2}(Z|[+-]\d{2}:\d{2})?)?)?)?)?)?)?$/,
				);
			}
			case Scorm2004DataType.TimeInterval: {
				return !!value.match(
					/^P(\d+Y)?(\d+M)?(\d+D)?(T(\d+H)?(\d+M)?(\d+(\.\d{1,2})?S)?)?$/,
				);
			}
			case Scorm2004DataType.Status: {
				return isScorm2004Status(value);
			}
			case Scorm2004DataType.Credit: {
				return isScormCredit(value);
			}
			case Scorm2004DataType.InteractionType: {
				return isScorm2004InteractionType(value);
			}
			case Scorm2004DataType.AudioCaptioning: {
				return isScorm2004AudioCaptioning(value);
			}
			case Scorm2004DataType.LessonMode: {
				return isScormLessonMode(value);
			}
			case Scorm2004DataType.SuccessStatus: {
				return isScorm2004SuccessStatus(value);
			}
			case Scorm2004DataType.TimeLimitAction: {
				return isScormTimeLimitAction(value);
			}
			case Scorm2004DataType.NavRequestValid: {
				return isScorm2004NavRequestValid(value);
			}
			default: {
				return assertNever(type);
			}
		}
	}

	/**
	 * Set a Scorm model value by key
	 * @param key the Scorm model key, e.g. 'cmi.suspend_data'
	 * @param value the value to set
	 */
	public setValue(key: string, value: string) {
		const prop = findPropForKey(key);
		if (prop === undefined) {
			throw new Scorm2004Error(
				Scorm2004ErrorCode.UNDEFINED_ELEMENT,
				`Undefined property: ${key}`,
			);
		}

		if ('propType' in prop && (prop.propType === 'const' || prop.propType === 'count')) {
			throw new Scorm2004Error(
				Scorm2004ErrorCode.ELEMENT_IS_READ_ONLY,
				`Property ${key} is read only`,
			);
		}

		if (prop.access === ScormPropertyAccess.ReadOnly) {
			throw new Scorm2004Error(
				Scorm2004ErrorCode.ELEMENT_IS_READ_ONLY,
				`Property ${key} is read only`,
			);
		}

		if (!this.validateDataType(prop.dataType, value)) {
			throw new Scorm2004Error(
				Scorm2004ErrorCode.INCORRECT_DATA_TYPE,
				`Invalid data type for key ${key}, type ${prop.dataType}, value: ${value}`,
			);
		}

		this.data.set(key, value);
	}

	private getCount(key: string): number {
		// e.g. key = 'cmi.objectives._count'

		// get the subKey: e.g. 'cmi.objectives.'
		const subKey = key.substring(0, key.length - 6);

		// find any data keys in the map that start with the sub key
		let max = -1;
		for (const dataKey of this.data.keys()) {
			if (dataKey.startsWith(subKey)) {
				// this data key starts with the sub key - e.g. 'cmi.objectives.3.id'

				// get the numeric index that comes after the subKey - e.g. '3.id'

				const dataSubKey = dataKey.substring(subKey.length);
				// make sure it matches what we expect
				const match = dataSubKey.match(/^(\d+)\./);
				if (match) {
					const index = Number(match[1]);
					if (index > max) {
						max = index;
					}
				}
			}
		}

		return max + 1;
	}

	/**
	 * Get a Scorm model value by key.
	 * @param key the Scorm model key, e.g. 'cmi.suspend_data'
	 * @returns the value for the key, or '' if no value has been set
	 */
	public getValue(key: string): string {
		const prop = findPropForKey(key);
		if (prop === undefined) {
			throw new Scorm2004Error(Scorm2004ErrorCode.UNDEFINED_ELEMENT);
		}

		if (prop.access === ScormPropertyAccess.WriteOnly) {
			throw new Scorm2004Error(Scorm2004ErrorCode.ELEMENT_IS_WRITE_ONLY);
		}

		if ('propType' in prop && prop.propType === 'const') {
			return prop.constValue;
		}

		if ('propType' in prop && prop.propType === 'count') {
			const count = this.getCount(key);
			return `${count}`;
		}

		const value = this.data.get(key);
		if (value === undefined) {
			return '';
		} else {
			return value;
		}
	}

	/**
	 * Get a raw value from the data store without doing any validation.
	 */
	public getRawValue(key: string): string | undefined {
		return this.data.get(key);
	}

	/**
	 * Serialise the entire model data to a record. Useful for debugging.
	 */
	public serialise(): Record<string, string> {
		const record: Record<string, string> = {};
		for (const [key, value] of this.data.entries()) {
			record[key] = value;
		}
		return record;
	}
}
