import { Scorm12ErrorCode, ScormReturnCode } from './enums';
import { getScorm12ErrorDescription, Scorm12Error } from './errors';
import { ScormAPIOptions, ScormSubmitResponse } from './scorm';
import { Scorm12RootSchema } from './Scorm12Schema';

/**
 * Scorm 1.2 js API interface
 */
export interface Scorm12APIInterface {
	LMSInitialize(arg?: string): ScormReturnCode;
	LMSGetValue(key: string): string;
	LMSSetValue(key: string, value: string): ScormReturnCode;
	LMSFinish(arg?: string): ScormReturnCode;
	LMSCommit(arg?: string): ScormReturnCode;
	LMSGetLastError(): Scorm12ErrorCode;
	LMSGetErrorString(code: string): string;
	LMSGetDiagnostic(arg?: string | number): string;
}

/**
 * Implementation of the Scorm 1.2 js API
 */
export class Scorm12API implements Scorm12APIInterface {
	private savePath: string;

	private lastError: Scorm12ErrorCode;
	private initialised: boolean;
	private finished: boolean;

	private schemaData: Scorm12RootSchema;

	private saving: boolean;
	private savePending: boolean;
	private pendingValues: Record<string, string>;

	private onFinish?: () => void;
	private onSubmitComplete?: (data: ScormSubmitResponse) => void;
	private onModuleCompleted?: () => boolean;

	public debug = false;

	constructor(options: ScormAPIOptions) {
		this.savePath = options.savePath;
		this.lastError = Scorm12ErrorCode.NO_ERROR;
		this.initialised = false;
		this.finished = false;
		this.saving = false;
		this.savePending = false;
		this.pendingValues = {};
		this.onFinish = options.onFinish;
		this.onSubmitComplete = options.onSubmitComplete;
		this.onModuleCompleted = options.onModuleCompleted;

		this.schemaData = new Scorm12RootSchema(options.data);
	}

	public debugScormData() {
		const record = this.schemaData.serialise();
		console.log(record);
	}

	public LMSInitialize(): ScormReturnCode {
		this.lastError = Scorm12ErrorCode.NO_ERROR;

		if (this.initialised) {
			if (this.debug) {
				console.log('[DEBUG] LMSInitialize() failed - already initialised');
			}
			return ScormReturnCode.FAILURE;
		}

		if (this.debug) {
			console.log('[DEBUG] LMSInitialize()');
		}

		this.initialised = true;
		return ScormReturnCode.SUCCESS;
	}

	public LMSGetValue(key: string): string {
		this.lastError = Scorm12ErrorCode.NO_ERROR;

		if (!this.initialised || this.finished) {
			if (this.debug) {
				if (!this.initialised) {
					console.log(`[DEBUG] LMSGetValue("${key}") failed - not initialised`);
				} else if (this.finished) {
					console.log(`[DEBUG] LMSGetValue("${key}") failed - already finished`);
				}
			}

			this.lastError = Scorm12ErrorCode.NOT_INITIALISED;
			return '';
		}

		try {
			const result = this.schemaData.getValue(key);

			if (this.debug) {
				console.log(`[DEBUG] LMSGetValue("${key}") -> "${result}"`);
			}

			return result;
		} catch (err) {
			if (this.debug) {
				console.log(`[DEBUG] LMSGetValue("${key}") threw error:`, err);
			}

			if (err instanceof Scorm12Error) {
				this.lastError = err.code;
			} else {
				this.lastError = Scorm12ErrorCode.GENERAL_ERROR;
			}
			return '';
		}
	}

	public LMSSetValue(key: string, value: string): ScormReturnCode {
		this.lastError = Scorm12ErrorCode.NO_ERROR;

		if (!this.initialised || this.finished) {
			if (this.debug) {
				if (!this.initialised) {
					console.log(
						`[DEBUG] LMSSetValue("${key}", "${value}") failed - not initialised`,
					);
				} else if (this.finished) {
					console.log(
						`[DEBUG] LMSSetValue("${key}", "${value}") failed - already finished`,
					);
				}
			}

			this.lastError = Scorm12ErrorCode.NOT_INITIALISED;
			return ScormReturnCode.FAILURE;
		}

		const strValue = `${value}`;

		const currentValue = this.schemaData.getRawValue(key);
		if (currentValue === strValue) {
			if (this.debug) {
				console.log(`[DEBUG] LMSSetValue("${key}", "${value}") - value unchanged`);
			}

			// no change
			return ScormReturnCode.SUCCESS;
		}

		try {
			this.schemaData.setValue(key, strValue);
			this.pendingValues[key] = strValue;

			if (this.debug) {
				console.log(`[DEBUG] LMSSetValue("${key}", "${value}")`);
			}

			if (key === 'cmi.core.lesson_status' && this.onModuleCompleted) {
				const wasPreviouslyCompleted =
					currentValue === 'completed' || currentValue === 'passed';
				const isNowCompleted = strValue === 'completed' || strValue === 'passed';
				if (!wasPreviouslyCompleted && isNowCompleted) {
					const result = this.onModuleCompleted();
					if (result) {
						this.doSave();
					}
				}
			}
		} catch (err) {
			console.log(err);

			if (this.debug) {
				console.log(`[DEBUG] LMSSetValue("${key}", "${value}") threw error:`, err);
			}

			if (err instanceof Scorm12Error) {
				this.lastError = err.code;
			} else {
				this.lastError = Scorm12ErrorCode.GENERAL_ERROR;
			}
			return ScormReturnCode.FAILURE;
		}

		return ScormReturnCode.SUCCESS;
	}

	public LMSFinish(): ScormReturnCode {
		this.lastError = Scorm12ErrorCode.NO_ERROR;

		if (!this.initialised || this.finished) {
			if (this.debug) {
				if (!this.initialised) {
					console.log(`[DEBUG] LMSFinish() failed - not initialised`);
				} else if (this.finished) {
					console.log(`[DEBUG] LMSFinish() failed - already finished`);
				}
			}

			return ScormReturnCode.FAILURE;
		}

		if (this.debug) {
			console.log(`[DEBUG] LMSFinish()`);
		}

		// call doSave if there are pending values
		if (Object.keys(this.pendingValues).length > 0) {
			this.doSave();
		}

		this.finished = true;

		// run onFinish() after LMSFinish() has returned
		setTimeout(() => {
			if (this.onFinish) {
				this.onFinish();
			}
		}, 0);

		return ScormReturnCode.SUCCESS;
	}

	public LMSCommit(): ScormReturnCode {
		if (this.debug) {
			console.log(`[DEBUG] LMSCommit()`);
		}

		this.doSave();
		return ScormReturnCode.SUCCESS;
	}

	private async doSave(): Promise<boolean> {
		if (this.saving) {
			// wait for the current save to complete, then save
			this.savePending = true;
			return false;
		}

		if (Object.keys(this.pendingValues).length === 0) {
			// nothing to save
			return false;
		}

		this.saving = true;
		this.savePending = false;

		const body = JSON.stringify(this.pendingValues);
		this.pendingValues = {};

		if (this.debug) {
			console.log(`[DEBUG] Saving SCORM data to LMS`);
		}

		try {
			const response = await fetch(this.savePath, {
				method: 'POST',
				headers: {
					ContentType: 'application/json',
				},
				body,

				// This can be called from a beforeunload event, which would effectively cancel the request
				// as soon as the page is unloaded (e.g. when navigating away or closing the tab). Set keepalive
				// to true so that the browser (hopefully) still completes the request.
				keepalive: true,
			});
			if (!response.ok) {
				throw new Error(`LMS returned error ${response.status}: ${response.statusText}`);
			}

			if (this.onSubmitComplete) {
				const responseData = await response.json();
				this.onSubmitComplete(responseData);
			}
		} catch (err) {
			console.log('Error saving SCORM data to LMS:', err);
		}

		this.saving = false;

		if (this.savePending) {
			this.doSave();
		}

		return true;
	}

	public LMSGetLastError(): Scorm12ErrorCode {
		if (this.debug) {
			console.log('[DEBUG] LMSGetLastError()');
		}

		return this.lastError;
	}

	public LMSGetErrorString(code: string): string {
		if (this.debug) {
			console.log(`[DEBUG] LMSGetErrorString("${code}")`);
		}

		return getScorm12ErrorDescription(code);
	}

	public LMSGetDiagnostic(): string {
		if (this.debug) {
			console.log('[DEBUG] LMSGetDiagnostic()');
		}

		return '';
	}

	public isModuleCompleted(): boolean {
		const value = this.schemaData.getRawValue('cmi.core.lesson_status');
		return value === 'completed' || value === 'passed';
	}
}
