/**
 * Find the appropriate label text for the fieldset that a given form element is part of.
 */
export const getFieldsetLabelForField = (el: HTMLInputElement): string | undefined => {
	const fieldset = el.closest('fieldset');
	if (!fieldset) {
		return undefined;
	}

	// find a legend element
	const legend = fieldset.querySelector('legend');
	if (legend?.textContent) {
		return legend.textContent;
	}

	// find the element that the fieldset is labelled by
	const id = fieldset.getAttribute('aria-labelledby');
	if (id) {
		const elem = document.getElementById(id);
		if (elem?.textContent) {
			return elem.textContent;
		}
	}

	return undefined;
};
