/*****************************************************************************/
/* Common SCORM types                                                        */
/*****************************************************************************/

export enum ScormPropertyAccess {
	ReadOnly = 'ReadOnly',
	WriteOnly = 'WriteOnly',
	ReadWrite = 'ReadWrite',
}

export enum ScormCredit {
	Credit = 'credit',
	NoCredit = 'no-credit',
}
export const isScormCredit = (val: unknown): val is ScormCredit =>
	Object.values(ScormCredit).includes(val as ScormCredit);

export enum ScormLessonMode {
	Browse = 'browse',
	Normal = 'normal',
	Review = 'review',
}
export const isScormLessonMode = (val: unknown): val is ScormLessonMode =>
	Object.values(ScormLessonMode).includes(val as ScormLessonMode);

export enum ScormTimeLimitAction {
	ExitWithMessage = 'exit,message',
	ExitNoMessage = 'exit,no message',
	ContinueWithMessage = 'continue,message',
	ContinueNoMessage = 'continue,no message',
}
export const isScormTimeLimitAction = (val: unknown): val is ScormTimeLimitAction =>
	Object.values(ScormTimeLimitAction).includes(val as ScormTimeLimitAction);

export enum ScormReturnCode {
	SUCCESS = 'true',
	FAILURE = 'false',
}

/*****************************************************************************/
/* SCORM 1.2 types                                                           */
/*****************************************************************************/

export enum Scorm12DataType {
	CMIString255 = 'CMIString255',
	CMIString4096 = 'CMIString4096',
	CMIInteger = 'CMIInteger',
	CMISInteger = 'CMISInteger',
	CMIIdentifier = 'CMIIdentifier',
	CMIDecimal = 'CMIDecimal',
	CMIDecimalOrBlank = 'CMIDecimalOrBlank',
	CMITimespan = 'CMITimespan',
	CMITime = 'CMITime',
	CMICredit = 'CMICredit',
	CMIEntryOrBlank = 'CMIEntryOrBlank',
	CMILessonMode = 'CMILessonMode',
	CMIExitOrBlank = 'CMIExitOrBlank',
	CMIStatus = 'CMIStatus',
	CMITimeLimitAction = 'CMITimeLimitAction',
	CMIInteractionType = 'CMIInteractionType',
	CMIInteractionResult = 'CMIInteractionResult',
	CMIFeedback = 'CMIFeedback',
}

export enum Scorm12Status {
	Passed = 'passed',
	Completed = 'completed',
	Failed = 'failed',
	Incomplete = 'incomplete',
	Browsed = 'browsed',
	NotAttempted = 'not attempted',
}
export const isScorm12Status = (val: unknown): val is Scorm12Status =>
	Object.values(Scorm12Status).includes(val as Scorm12Status);

export enum Scorm12Entry {
	Initial = 'ab-initio',
	Resume = 'resume',
}
export const isScorm12Entry = (val: unknown): val is Scorm12Entry =>
	Object.values(Scorm12Entry).includes(val as Scorm12Entry);

export enum Scorm12Exit {
	TimeOut = 'time-out',
	Suspend = 'suspend',
	Logout = 'logout',
}
export const isScorm12Exit = (val: unknown): val is Scorm12Exit =>
	Object.values(Scorm12Exit).includes(val as Scorm12Exit);

export enum Scorm12InteractionType {
	TrueFalse = 'true-false',
	Choice = 'choice',
	FillIn = 'fill-in',
	Matching = 'matching',
	Performance = 'performance',
	Sequencing = 'sequencing',
	Likert = 'likert',
	Numeric = 'numeric',
}
export const isScorm12InteractionType = (val: unknown): val is Scorm12InteractionType =>
	Object.values(Scorm12InteractionType).includes(val as Scorm12InteractionType);

export enum Scorm12InteractionResult {
	Correct = 'correct',
	Wrong = 'wrong',
	Unanticipated = 'unanticipated',
	Neutral = 'neutral',
}
export const isScorm12InteractionResult = (val: unknown): val is Scorm12InteractionResult =>
	Object.values(Scorm12InteractionResult).includes(val as Scorm12InteractionResult);

export enum Scorm12ErrorCode {
	NO_ERROR = '0',
	GENERAL_ERROR = '101',
	INVALID_ARGUMENT = '201',
	ELEMENT_CANNOT_HAVE_CHILDREN = '202',
	ELEMENT_NOT_AN_ARRAY = '203',
	NOT_INITIALISED = '301',
	NOT_IMPLEMENTED = '401',
	ELEMENT_IS_KEYWORD = '402',
	ELEMENT_IS_READ_ONLY = '403',
	ELEMENT_IS_WRITE_ONLY = '404',
	INCORRECT_DATA_TYPE = '405',
}

/*****************************************************************************/
/* SCORM 2004 types                                                          */
/*****************************************************************************/

export enum Scorm2004DataType {
	CharacterString = 'CharacterString',
	LocalizedString = 'LocalizedString',
	LongIdentifier = 'LongIdentifier',
	ShortIdentifier = 'ShortIdentifier',
	Integer = 'Integer',
	Real = 'Real',
	Time = 'Time',
	TimeInterval = 'TimeInterval',
	// enums
	Status = 'Status',
	Credit = 'Credit',
	InteractionType = 'InteractionType',
	AudioCaptioning = 'AudioCaptioning',
	LessonMode = 'LessonMode',
	SuccessStatus = 'SuccessStatus',
	TimeLimitAction = 'TimeLimitAction',
	NavRequestValid = 'NavRequestValid',
}

export enum Scorm2004Status {
	Completed = 'completed',
	Incomplete = 'incomplete',
	NotAttempted = 'not attempted',
	Unknown = 'unknown',
}
export const isScorm2004Status = (val: unknown): val is Scorm2004Status =>
	Object.values(Scorm2004Status).includes(val as Scorm2004Status);

export enum Scorm2004InteractionType {
	TrueFalse = 'true-false',
	MultipleChoice = 'choice',
	FillIn = 'fill-in',
	LongFillIn = 'long-fill-in',
	Likert = 'likert',
	Matching = 'matching',
	Performance = 'performance',
	Sequencing = 'sequencing',
	Numeric = 'numeric',
	Other = 'other',
}
export const isScorm2004InteractionType = (val: unknown): val is Scorm2004InteractionType =>
	Object.values(Scorm2004InteractionType).includes(val as Scorm2004InteractionType);

export enum Scorm2004AudioCaptioning {
	Off = '-1',
	On = '1',
	NoChange = '0',
}
export const isScorm2004AudioCaptioning = (val: unknown): val is Scorm2004AudioCaptioning =>
	Object.values(Scorm2004AudioCaptioning).includes(val as Scorm2004AudioCaptioning);

export enum Scorm2004SuccessStatus {
	Passed = 'passed',
	Failed = 'failed',
	Unknown = 'unknown',
}
export const isScorm2004SuccessStatus = (val: unknown): val is Scorm2004SuccessStatus =>
	Object.values(Scorm2004SuccessStatus).includes(val as Scorm2004SuccessStatus);

export enum Scorm2004NavRequestValid {
	True = 'true',
	False = 'false',
	Unknown = 'unknown',
}
export const isScorm2004NavRequestValid = (val: unknown): val is Scorm2004NavRequestValid =>
	Object.values(Scorm2004NavRequestValid).includes(val as Scorm2004NavRequestValid);

export enum Scorm2004ErrorCode {
	NO_ERROR = '0',
	GENERAL_ERROR = '101',
	GENERAL_INITIALIZATION_FAILURE = '102',
	ALREADY_INITIALIZED = '103',
	CONTENT_INSTANCE_TERMINATED = '104',
	GENERAL_TERMINATION_FAILURE = '111',
	TERMINATION_BEFORE_INITIALIZATION = '112',
	TERMINATION_AFTER_TERMINATION = '113',
	RETRIEVE_DATA_BEFORE_INITIALIZATION = '122',
	RETRIEVE_DATA_AFTER_TERMINATION = '123',
	STORE_DATA_BEFORE_INITIALIZATION = '132',
	STORE_DATA_AFTER_TERMINATION = '133',
	COMMIT_BEFORE_INITIALIZATION = '142',
	COMMIT_AFTER_TERMINATION = '143',

	GENERAL_ARGUMENT_ERROR = '201',
	GET_FAILURE = '301',
	SET_FAILURE = '351',
	COMMIT_FAILURE = '391',
	UNDEFINED_ELEMENT = '401',
	UNIMPLEMENTED_ELEMENT = '402',
	ELEMENT_NOT_INITIALIZED = '403',
	ELEMENT_IS_READ_ONLY = '404',
	ELEMENT_IS_WRITE_ONLY = '405',
	INCORRECT_DATA_TYPE = '406',
	ELEMENT_VALUE_OUT_OF_RANGE = '407',
	DEPENDENCY_NOT_ESTABLISHED = '408',
}
