import { type AlpineComponent } from 'alpinejs';

import { FormFieldInfo, getFormValidationErrors } from '../formValidation';
import { PaymentFormStyles } from './styles';

/**
 * Data passed to the Alpine.js component from the backend.
 * See CoursePaymentController::getFormDetailsJSON().
 */
export interface PaymentFormData {
	PaymentContainerID: string;
	PayWayPublicApiKey: string;
	FirstName: string;
	LastName: string;
	Address: string | null;
	State: string | null;
	Postcode: string | null;
	Email: string;
}

type PaymentFormState = {
	data: PaymentFormData;
	submissionAttempted: boolean;
	isPaymentValid: boolean;
	isFormValid: boolean;
	isProcessing: boolean;
	invalidFields: FormFieldInfo[];
	isErrorsVisible: boolean;
	$refs: {
		errors: HTMLDivElement;
		form: HTMLFormElement;
	};
	init: () => void;
	areFormElementsValid: (updateFields?: boolean) => boolean;
	checkValid: () => void;
	handleValidation: ($event: Event) => void;
};

/**
 * Alpine.js data component for the training course payment form.
 */
export const paymentForm = (data: PaymentFormData): AlpineComponent<PaymentFormState> => {
	return {
		data,
		submissionAttempted: false,
		isPaymentValid: false,
		isFormValid: false,
		isProcessing: false,
		invalidFields: [],
		get isErrorsVisible() {
			return this.submissionAttempted && (!this.isFormValid || !this.isPaymentValid);
		},

		$refs: {} as {
			errors: HTMLDivElement;
			form: HTMLFormElement;
		},

		init() {
			const { form } = this.$refs;
			if (!form) return;

			if (!window.payway) {
				// this shouldn't happen - but in case it does, show an error message
				// and remove the "submit" button

				console.log('payway.js not found');

				const container = document.getElementById(data.PaymentContainerID);
				if (container) {
					container.innerHTML =
						'<h2>Error loading Payment form. payway.js not found.</h2>';
				}

				return;
			}

			// call PayWay API to set up the credit card iframe
			window.payway.createCreditCardFrame({
				layout: 'narrow',
				container: data.PaymentContainerID,
				publishableApiKey: data.PayWayPublicApiKey,
				onValid: () => {
					this.isPaymentValid = true;
					this.checkValid();
				},
				onInvalid: () => {
					this.isPaymentValid = false;
					this.checkValid();
				},
				style: PaymentFormStyles,
			});
		},

		// Returns whether all form fields have been entered.
		// This does not check the credit card fields.
		areFormElementsValid(updateFields = false) {
			const { form } = this.$refs;
			if (!form) return false;

			const invalidFields = getFormValidationErrors(form);

			if (updateFields) {
				this.invalidFields = invalidFields;
			}

			return invalidFields.length === 0;
		},

		// Checks whether the form is valid, and enables or disables the submit button.
		checkValid() {
			this.isFormValid = this.areFormElementsValid(this.submissionAttempted);
		},

		handleValidation(e) {
			this.submissionAttempted = true;
			this.checkValid();

			// If the form is not valid, prevent submission
			if (!this.isFormValid || !this.isPaymentValid) {
				e.stopImmediatePropagation();
			}

			// If the form is valid, submit the form
			if (this.isFormValid && this.isPaymentValid) {
				this.isProcessing = true;
			} else if (this.isFormValid && !this.isPaymentValid) {
				// If the form is valid but the payment is not, focus on errors
				// Unfortunately $nextTick doesn't work here, so let's force errors to be visible first
				this.$refs.errors.setAttribute('style', '');
				this.$refs.errors.focus();
			}
		},
	};
};
