import Player from '@vimeo/player';

/**
 * Course topic data returned from the backend.
 * See CoursePageController#getCourseTopicDataJSON().
 */
interface CourseTopicData {
	CompletedLink: string;
	Topic: {
		TopicContents: CourseTopicContentData[];
	};
}

interface CourseTopicContentDataBase {
	ID: number;
	Title: string;
	CallbackUrl: string;
	CanAccess: boolean;
	IsViewed: boolean;
	IsCompleted: boolean;
}

interface CourseTopicVideoData extends CourseTopicContentDataBase {
	IsQuiz: false;
	IsVideo: true;
	IsLearningMaterial: false;
	VideoLink: string;
}

interface CourseTopicQuizData extends CourseTopicContentDataBase {
	IsQuiz: true;
	IsVideo: false;
	IsLearningMaterial: false;
}

interface CourseTopicLearningMaterialData extends CourseTopicContentDataBase {
	IsQuiz: false;
	IsVideo: false;
	IsLearningMaterial: true;
}

type CourseTopicContentData =
	| CourseTopicQuizData
	| CourseTopicVideoData
	| CourseTopicLearningMaterialData;

export type CourseTopicContentViewedResponse = {
	IsNowCompleted: boolean;
};

const courseTopic = (initialData: CourseTopicData | undefined = undefined) => {
	return {
		course: initialData,
		video: undefined as CourseTopicVideoData | undefined,
		courseCompleted: false,
		videoCompletedForFirstTime: false,

		setVideo(id: number) {
			const video = this.course?.Topic?.TopicContents.find((obj) => obj.ID === id);
			if (!video?.IsVideo) return;

			this.video = video;
			this.videoCompletedForFirstTime = false;
		},

		async initVideo() {
			if (!this.video) return;

			const dialog = document.getElementById('video-dialog') as HTMLDialogElement;
			if (!dialog) return;
			dialog.showModal();

			// create a close event listener so we can clean up when the modal closes
			const closeListener = () => {
				this.cleanupVideo();
				dialog.removeEventListener('close', closeListener);
			};

			dialog.addEventListener('close', closeListener);

			// get vimeo url
			const url = document.getElementById('video-embed')?.dataset.vimeoUrl;
			const player = new Player('video-embed', { url });
			await player.ready();

			player.on('ended', () => this.videoEnded());
		},

		async videoEnded() {
			if (!this.video) return;

			if (!this.video.IsViewed) {
				this.videoCompletedForFirstTime = true;
			}

			// fire event back to the server
			const rsp = await fetch(this.video.CallbackUrl, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					contentId: this.video.ID,
				}),
			});

			const data = (await rsp.json()) as CourseTopicContentViewedResponse;

			if (data.IsNowCompleted) {
				// the user has now completed the course, so redirect to
				// the complete page when they close the video modal
				this.courseCompleted = true;
			}
		},

		closeVideo() {
			const dialog = document.getElementById('video-dialog') as HTMLDialogElement;
			if (!dialog) return;

			dialog.close();
		},

		// cleanupVideo() is called after the video dialog is closed, either by
		// clicking the close button, or pressing ESC
		cleanupVideo() {
			if (this.courseCompleted && this.course) {
				// this was the last content item in the course, so when the user
				// closes the video, redirect them to the 'completed' page
				window.location.href = this.course.CompletedLink;
			} else {
				this.video = undefined;

				if (this.videoCompletedForFirstTime) {
					// User has completed the video for the first time.
					// Reload the page so the topic progression UI is updated.
					window.location.reload();
				}
			}
		},
	};
};

export default courseTopic;
