import { getFieldsetLabelForField } from './elementUtils';

export const getFormValidationErrors = (form: HTMLFormElement): FormFieldInfo[] => {
	const invalidFields: FormFieldInfo[] = [];

	for (const el of form.elements) {
		if (!(el instanceof HTMLInputElement || el instanceof HTMLSelectElement)) {
			continue;
		}

		if (el.validity.valid) {
			continue;
		}

		let label = el.labels?.[0].textContent || '';
		if (el instanceof HTMLInputElement && el.type === 'radio') {
			// don't show multiple errors for radio options
			if (invalidFields.find((f) => f.name === el.name)) {
				continue;
			}
			// use the fieldset legend/label text
			label = getFieldsetLabelForField(el) || '';
		}

		invalidFields.push({
			id: el.id,
			name: el.name,
			label,
			link: `${window.location.origin}${window.location.pathname}#${el.id}`,
		});
	}

	return invalidFields;
};

export interface FormFieldInfo {
	id: string;
	name: string;
	label: string;
	link: string;
}
