import type { CreateCreditCardFrameStyleOptions } from './types';

// Set up style overrides for the westpac payway form.

export const PaymentFormStyles: CreateCreditCardFrameStyleOptions = {
	// Style the container and set up the variables
	'.payway-card': {
		// Base values used for calculating position of form elements
		// The elements are positioned absolutely and we can't touch the DOM so this is the only way :(
		// If you change these you may also need to update the iframe heights in `CoursePaymentController.ss`
		'--card-padding': '1.5rem',
		'--label-height': '1.5rem',
		'--label-spacing': '0.25rem',
		'--input-height': '3.4375rem',
		'--input-spacing': '1.5rem',
		// Computed values used for positioning and sizing form elements
		'--full-width': 'calc(100% - var(--card-padding) * 2)',
		'--half-width': 'calc((100% - var(--input-spacing) - var(--card-padding) * 2) / 2)',
		'--input-y': 'calc(var(--label-height) + var(--label-spacing))',
		'--element-height':
			'calc(var(--label-height) + var(--label-spacing) + var(--input-height))',
		'--col-one-x': 'var(--card-padding)',
		'--col-two-x': 'calc(50% + var(--input-spacing) / 2)',
		'--row-one-y': 'var(--card-padding)',
		'--row-two-y': 'calc(var(--card-padding) + var(--element-height) + var(--input-spacing))',
		'--row-three-y':
			'calc(var(--card-padding) + (var(--element-height) + var(--input-spacing)) * 2)',
		'--row-four-y':
			'calc(var(--card-padding) + (var(--element-height) + var(--input-spacing)) * 3)',
		// Colours based on the Tailwind theme
		'--color-text-primary': '#0e0f11', // colors.text.primary.DEFAULT
		'--color-border-primary': '#d1d6dd', // colors.border.primary.DEFAULT
		'--color-border-input': '#293891', // colors.action.primary.enabled
		'--color-surface-primary': '#fdfdfd', // colors.surface.primary.DEFAULT
		'--color-surface-muted': '#f4f5f7', // colors.surface.primary.muted
		'--color-ring': '#f15b29', // colors.interaction.focused
		// Background copied from `surface-gradient-radial` in `tailwind.config.js`
		'background-image':
			'radial-gradient(circle at 0% -20%, rgba(42, 57, 144, 0.1) 0%, rgba(29, 117, 188, 0.1) 23%, rgba(191, 30, 45, 0.1) 50%, rgba(241, 91, 41, 0.1) 66%, rgba(0, 104, 56, 0.1) 94%)',
		'border-color': 'var(--color-border-primary)',
		'border-radius': '0.5rem',
		'box-sizing': 'border-box',
		color: 'var(--color-text-primary) !important',
		height: 'calc(var(--row-four-y) + var(--element-height) + var(--card-padding)) !important',
		width: '100% !important',
	},
	// Style the "Test" label
	'.payway-creditcard-testfacility': {
		'border-radius': '0.5rem',
		'border-top-left-radius': '0',
		'border-top-right-radius': '0',
		'font-size': '0.75rem !important',
		left: 'var(--card-padding) !important',
		right: 'auto !important',
		'text-transform': 'uppercase',
		width: 'auto !important',
	},
	// Style the "Visa"/"Mastercard"/etc label
	'.payway-type-read-only': {
		border: '1px solid color-mix(in srgb, currentColor 15%, transparent) !important',
		'border-radius': '0.5rem',
		'border-top-left-radius': '0',
		'border-top-right-radius': '0',
		'border-top-width': '0px !important',
		bottom: 'auto !important',
		right: 'var(--card-padding) !important',
		'text-transform': 'uppercase',
		top: '0 !important',
	},
	// Style the labels
	'label.payway-name, label.payway-number, label.payway-csc, fieldset.payway-expiration legend': {
		color: 'var(--color-text-primary)',
		'font-size': '0.9375rem !important',
		'font-style': 'normal',
		'font-weight': '700',
		height: 'var(--label-height)',
		'letter-spacing': '0em',
		'line-height': '1.5',
		'text-align': 'left !important',
	},
	// Style the inputs
	'input.payway-number-formatted, input.payway-csc, input.payway-name, select.payway-month, select.payway-year':
		{
			'background-color': 'var(--color-surface-muted)',
			'border-color': 'var(--color-border-input) !important',
			'border-radius': '0.5rem',
			'box-sizing': 'border-box',
			color: 'var(--color-text-primary)',
			'font-size': '0.875rem !important',
			height: 'var(--input-height)',
			padding: '0.5rem 1rem',
			transition: 'box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1)',
		},
	// Add a focus ring to the inputs (copied from Tailwind)
	'input:focus-visible, select:focus-visible, input:focus-within, select:focus-within': {
		outline: '2px solid transparent !important',
		'outline-offset': '2px',
		'box-shadow':
			'0 0 0 2px var(--color-surface-primary), 0 0 0 6px var(--color-ring) !important',
	},
	// Override default green border on valid inputs
	'input.payway-complete, select.payway-complete, fieldset.payway-complete select, fieldset.payway-complete input':
		{
			'border-color': 'var(--color-border-input) !important',
		},
	// Position the "Name on Card" label and input
	'label.payway-name': {
		left: 'var(--col-one-x) !important',
		top: 'var(--row-one-y) !important',
		width: 'var(--full-width) !important',
	},
	'input.payway-name': {
		top: 'var(--input-y) !important',
		width: '100% !important',
	},
	// Position the "Card Number" label and input
	'label.payway-number': {
		left: 'var(--col-one-x) !important',
		top: 'var(--row-two-y) !important',
		width: 'var(--full-width) !important',
	},
	'input.payway-number-formatted': {
		top: 'var(--input-y) !important',
		width: '100% !important',
	},
	// Position the "Expiration" label and inputs
	'fieldset.payway-expiration': {
		left: 'var(--card-padding) !important',
		top: 'var(--row-three-y) !important',
		width: 'var(--full-width) !important',
	},
	'select.payway-month, select.payway-year': {
		'padding-left': '0 !important',
		'padding-right': '0 !important',
		'text-align': 'center !important',
		top: 'var(--label-spacing) !important',
		width: 'calc(50% - var(--input-spacing) / 2) !important',
	},
	// Position the freaking slash between the month and year (you gotta be kidding me)
	'label.payway-creditcard-expirationseparator': {
		bottom: 'auto !important',
		color: 'var(--color-border-input)',
		'font-weight': '700',
		left: '50% !important',
		right: 'auto !important',
		transform: 'translateX(-50%) translateY(-50%) !important',
		top: 'calc(var(--row-three-y) + var(--input-y) + (var(--input-height) / 2)) !important',
		width: 'auto !important',
	},
	// Position the "Security Code" label and input
	'label.payway-csc': {
		left: 'var(--card-padding) !important',
		top: 'var(--row-four-y) !important',
		width: 'var(--full-width) !important',
	},
	'input.payway-csc': {
		right: 'auto !important',
		top: 'var(--input-y) !important',
		width: '100% !important',
	},
	// Show expiration and security code on one line for bigger screens
	'@media (min-width: 25em)': {
		'.payway-card': {
			height: 'calc(var(--row-three-y) + var(--element-height) + var(--card-padding)) !important',
		},
		'fieldset.payway-expiration': {
			top: 'var(--row-three-y) !important',
			width: 'var(--half-width) !important',
		},
		'label.payway-creditcard-expirationseparator': {
			left: 'calc(var(--col-one-x) + (var(--half-width) / 2)) !important',
		},
		'label.payway-csc': {
			left: 'var(--col-two-x) !important',
			top: 'var(--row-three-y) !important',
			width: 'var(--half-width) !important',
		},
	},
	// Also show name and number on one line for the biggest screens
	'@media (min-width: 31.25em)': {
		'.payway-card': {
			height: 'calc(var(--row-two-y) + var(--element-height) + var(--card-padding)) !important',
		},
		'label.payway-name': {
			width: 'var(--half-width) !important',
		},
		'label.payway-number': {
			left: 'var(--col-two-x) !important',
			top: 'var(--row-one-y) !important',
			width: 'var(--half-width) !important',
		},
		'fieldset.payway-expiration': {
			top: 'var(--row-two-y) !important',
		},
		'label.payway-creditcard-expirationseparator': {
			top: 'calc(var(--row-two-y) + var(--input-y) + (var(--input-height) / 2)) !important',
		},
		'label.payway-csc': {
			top: 'var(--row-two-y) !important',
		},
	},
};
