import {
	isScormCredit,
	isScorm12Entry,
	isScorm12Exit,
	isScorm12InteractionResult,
	isScorm12InteractionType,
	isScormLessonMode,
	isScorm12Status,
	isScormTimeLimitAction,
	ScormPropertyAccess,
	Scorm12DataType,
	Scorm12ErrorCode,
} from './enums';
import { Scorm12Error } from './errors';

interface Scorm12PropertyBase {
	name: string | RegExp;
	dataType: Scorm12DataType;
	access: ScormPropertyAccess;
}

interface Scorm12ConstProperty extends Scorm12PropertyBase {
	propType: 'const';
	constValue: string;
}

interface Scorm12CountProperty extends Scorm12PropertyBase {
	propType: 'count';
}

type Scorm12Property = Scorm12PropertyBase | Scorm12ConstProperty | Scorm12CountProperty;

const assertNever = (val: never) => {
	throw new Error(`Unexpected value: ${val}`);
};

/**
 * All the valid Scorm 1.2 model properties.
 */
const Scorm12Props: Scorm12Property[] = [
	{
		name: 'cmi._version',
		dataType: Scorm12DataType.CMIString255,
		access: ScormPropertyAccess.ReadOnly,
		propType: 'const',
		constValue: '3.4',
	},
	{
		name: 'cmi.core._children',
		dataType: Scorm12DataType.CMIString255,
		access: ScormPropertyAccess.ReadOnly,
		propType: 'const',
		constValue:
			'student_id,student_name,lesson_location,credit,lesson_status,entry,score,total_time,lesson_mode,exit,session_time',
	},
	{
		name: 'cmi.core.student_id',
		dataType: Scorm12DataType.CMIIdentifier,
		access: ScormPropertyAccess.ReadOnly,
	},
	{
		name: 'cmi.core.student_name',
		dataType: Scorm12DataType.CMIString255,
		access: ScormPropertyAccess.ReadOnly,
	},
	{
		name: 'cmi.core.lesson_location',
		dataType: Scorm12DataType.CMIString255,
		access: ScormPropertyAccess.ReadWrite,
	},
	{
		name: 'cmi.core.credit',
		dataType: Scorm12DataType.CMICredit,
		access: ScormPropertyAccess.ReadOnly,
	},
	{
		name: 'cmi.core.lesson_status',
		dataType: Scorm12DataType.CMIStatus,
		access: ScormPropertyAccess.ReadWrite,
	},
	{
		name: 'cmi.core.entry',
		dataType: Scorm12DataType.CMIEntryOrBlank,
		access: ScormPropertyAccess.ReadOnly,
	},
	{
		name: 'cmi.core.score._children',
		dataType: Scorm12DataType.CMIString255,
		access: ScormPropertyAccess.ReadOnly,
		propType: 'const',
		constValue: 'raw,min,max',
	},
	{
		name: 'cmi.core.score.raw',
		dataType: Scorm12DataType.CMIDecimalOrBlank,
		access: ScormPropertyAccess.ReadWrite,
	},
	{
		name: 'cmi.core.score.max',
		dataType: Scorm12DataType.CMIDecimalOrBlank,
		access: ScormPropertyAccess.ReadWrite,
	},
	{
		name: 'cmi.core.score.min',
		dataType: Scorm12DataType.CMIDecimalOrBlank,
		access: ScormPropertyAccess.ReadWrite,
	},
	{
		name: 'cmi.core.total_time',
		dataType: Scorm12DataType.CMITimespan,
		access: ScormPropertyAccess.ReadOnly,
	},
	{
		name: 'cmi.core.lesson_mode',
		dataType: Scorm12DataType.CMILessonMode,
		access: ScormPropertyAccess.ReadOnly,
	},
	{
		name: 'cmi.core.exit',
		dataType: Scorm12DataType.CMIExitOrBlank,
		access: ScormPropertyAccess.WriteOnly,
	},
	{
		name: 'cmi.core.session_time',
		dataType: Scorm12DataType.CMITimespan,
		access: ScormPropertyAccess.WriteOnly,
	},
	{
		name: 'cmi.suspend_data',
		dataType: Scorm12DataType.CMIString4096,
		access: ScormPropertyAccess.ReadWrite,
	},
	{
		name: 'cmi.launch_data',
		dataType: Scorm12DataType.CMIString4096,
		access: ScormPropertyAccess.ReadOnly,
	},
	{
		name: 'cmi.comments',
		dataType: Scorm12DataType.CMIString4096,
		access: ScormPropertyAccess.ReadWrite,
	},
	{
		name: 'cmi.comments_from_lms',
		dataType: Scorm12DataType.CMIString4096,
		access: ScormPropertyAccess.ReadOnly,
	},
	{
		name: 'cmi.objectives._children',
		dataType: Scorm12DataType.CMIString255,
		access: ScormPropertyAccess.ReadOnly,
		propType: 'const',
		constValue: 'id,score,status',
	},
	{
		name: 'cmi.objectives._count',
		dataType: Scorm12DataType.CMIInteger,
		access: ScormPropertyAccess.ReadOnly,
		propType: 'count',
	},
	{
		name: /^cmi\.objectives\.(\d+)\.id$/,
		dataType: Scorm12DataType.CMIIdentifier,
		access: ScormPropertyAccess.ReadWrite,
	},
	{
		name: /^cmi\.objectives\.(\d+)\.score._children$/,
		dataType: Scorm12DataType.CMIString255,
		access: ScormPropertyAccess.ReadOnly,
		propType: 'const',
		constValue: 'raw,min,max',
	},
	{
		name: /^cmi\.objectives\.(\d+)\.score.raw$/,
		dataType: Scorm12DataType.CMIDecimalOrBlank,
		access: ScormPropertyAccess.ReadWrite,
	},
	{
		name: /^cmi\.objectives\.(\d+)\.score.min$/,
		dataType: Scorm12DataType.CMIDecimalOrBlank,
		access: ScormPropertyAccess.ReadWrite,
	},
	{
		name: /^cmi\.objectives\.(\d+)\.score.max$/,
		dataType: Scorm12DataType.CMIDecimalOrBlank,
		access: ScormPropertyAccess.ReadWrite,
	},
	{
		name: /^cmi\.objectives\.(\d+)\.status$/,
		dataType: Scorm12DataType.CMIStatus,
		access: ScormPropertyAccess.ReadWrite,
	},
	{
		name: 'cmi.student_data._children',
		dataType: Scorm12DataType.CMIString255,
		access: ScormPropertyAccess.ReadOnly,
		propType: 'const',
		constValue: 'mastery_score,max_time_allowed,time_limit_action',
	},
	{
		name: 'cmi.student_data.mastery_score',
		dataType: Scorm12DataType.CMIDecimal,
		access: ScormPropertyAccess.ReadOnly,
	},
	{
		name: 'cmi.student_data.max_time_allowed',
		dataType: Scorm12DataType.CMITimespan,
		access: ScormPropertyAccess.ReadOnly,
	},
	{
		name: 'cmi.student_data.time_limit_action',
		dataType: Scorm12DataType.CMITimeLimitAction,
		access: ScormPropertyAccess.ReadOnly,
	},
	{
		name: 'cmi.student_preference._children',
		dataType: Scorm12DataType.CMIString255,
		access: ScormPropertyAccess.ReadOnly,
		propType: 'const',
		constValue: 'audio,language,speed,text',
	},
	{
		name: 'cmi.student_preference.audio',
		dataType: Scorm12DataType.CMISInteger,
		access: ScormPropertyAccess.ReadWrite,
	},
	{
		name: 'cmi.student_preference.language',
		dataType: Scorm12DataType.CMIString255,
		access: ScormPropertyAccess.ReadWrite,
	},
	{
		name: 'cmi.student_preference.speed',
		dataType: Scorm12DataType.CMISInteger,
		access: ScormPropertyAccess.ReadWrite,
	},
	{
		name: 'cmi.student_preference.text',
		dataType: Scorm12DataType.CMISInteger,
		access: ScormPropertyAccess.ReadWrite,
	},
	{
		name: 'cmi.interactions._children',
		dataType: Scorm12DataType.CMIString255,
		access: ScormPropertyAccess.ReadOnly,
		propType: 'const',
		constValue:
			'id,objectives,time,type,correct_responses,weighting,student_response,result,latency',
	},
	{
		name: 'cmi.interactions._count',
		dataType: Scorm12DataType.CMIInteger,
		access: ScormPropertyAccess.ReadOnly,
		propType: 'count',
	},
	{
		name: /^cmi\.interactions\.(\d+)\.id$/,
		dataType: Scorm12DataType.CMIIdentifier,
		access: ScormPropertyAccess.WriteOnly,
	},
	{
		name: /^cmi\.interactions\.(\d+)\.objectives\._count$/,
		dataType: Scorm12DataType.CMIInteger,
		access: ScormPropertyAccess.ReadOnly,
		propType: 'count',
	},
	{
		name: /^cmi\.interactions\.(\d+)\.objectives\.(\d+)\.id$/,
		dataType: Scorm12DataType.CMIIdentifier,
		access: ScormPropertyAccess.WriteOnly,
	},
	{
		name: /^cmi\.interactions\.(\d+)\.time$/,
		dataType: Scorm12DataType.CMITime,
		access: ScormPropertyAccess.WriteOnly,
	},
	{
		name: /^cmi\.interactions\.(\d+)\.type$/,
		dataType: Scorm12DataType.CMIInteractionType,
		access: ScormPropertyAccess.WriteOnly,
	},
	{
		name: /^cmi\.interactions\.(\d+)\.correct_responses\._count$/,
		dataType: Scorm12DataType.CMIInteger,
		access: ScormPropertyAccess.ReadOnly,
		propType: 'count',
	},
	{
		name: /^cmi\.interactions\.(\d+)\.correct_responses\.(\d+)\.pattern$/,
		dataType: Scorm12DataType.CMIFeedback,
		access: ScormPropertyAccess.WriteOnly,
	},
	{
		name: /^cmi\.interactions\.(\d+)\.weighting$/,
		dataType: Scorm12DataType.CMIDecimal,
		access: ScormPropertyAccess.WriteOnly,
	},
	{
		name: /^cmi\.interactions\.(\d+)\.student_response$/,
		dataType: Scorm12DataType.CMIFeedback,
		access: ScormPropertyAccess.WriteOnly,
	},
	{
		name: /^cmi\.interactions\.(\d+)\.result$/,
		dataType: Scorm12DataType.CMIInteractionResult,
		access: ScormPropertyAccess.WriteOnly,
	},
	{
		name: /^cmi\.interactions\.(\d+)\.latency$/,
		dataType: Scorm12DataType.CMITimespan,
		access: ScormPropertyAccess.WriteOnly,
	},
];

const findPropForKey = (key: string): Scorm12Property | undefined => {
	for (const prop of Scorm12Props) {
		// does this prop match the key?
		if (prop.name === key) {
			return prop;
		}

		if (typeof prop.name === 'object') {
			// regex
			if (key.match(prop.name)) {
				return prop;
			}
		}
	}

	return undefined;
};

/**
 * Scorm 1.2 data model schema.
 * This class contains the data values set by the LMS or the SCO, and provides access to
 * get and set values as appropriate. This handles validation of properties, data types
 * and access restrictions.
 */
export class Scorm12RootSchema {
	private data: Map<string, string>;

	/**
	 * Create an instance of this class and initialise the model with data from the LMS
	 */
	constructor(lmsData: Record<string, string>) {
		this.data = new Map();
		for (const key in lmsData) {
			this.data.set(key, lmsData[key]);
		}
	}

	private validateDataType(type: Scorm12DataType, value: string): boolean {
		switch (type) {
			case Scorm12DataType.CMIString255: {
				return value.length <= 255;
			}
			case Scorm12DataType.CMIString4096: {
				return value.length <= 4096;
			}
			case Scorm12DataType.CMIInteger: {
				if (!value.match(/^\d+$/)) {
					return false;
				}
				const nbr = Number(value);
				return nbr <= 65536;
			}
			case Scorm12DataType.CMISInteger: {
				if (!value.match(/^-?\d+$/)) {
					return false;
				}
				const nbr = Number(value);
				return nbr >= -32768 && nbr < 32768;
			}
			case Scorm12DataType.CMIIdentifier: {
				if (value.match(/\s/)) {
					return false;
				}
				return value.length <= 255;
			}
			case Scorm12DataType.CMIDecimal: {
				const nbr = Number(value);
				if (Number.isNaN(nbr)) return false;
				return true;
			}
			case Scorm12DataType.CMIDecimalOrBlank: {
				return value === '' || this.validateDataType(Scorm12DataType.CMIDecimal, value);
			}
			case Scorm12DataType.CMITimespan: {
				return !!value.match(/^\d{2,4}:\d{2}:\d{2}(\.\d{1,2})?$/);
			}
			case Scorm12DataType.CMITime: {
				return !!value.match(/^\d{2}:\d{2}:\d{2}(\.\d{1,2})?$/);
			}
			case Scorm12DataType.CMICredit: {
				return isScormCredit(value);
			}
			case Scorm12DataType.CMIEntryOrBlank: {
				return value === '' || isScorm12Entry(value);
			}
			case Scorm12DataType.CMILessonMode: {
				return isScormLessonMode(value);
			}
			case Scorm12DataType.CMIExitOrBlank: {
				return value === '' || isScorm12Exit(value);
			}
			case Scorm12DataType.CMIStatus: {
				return isScorm12Status(value);
			}
			case Scorm12DataType.CMITimeLimitAction: {
				return isScormTimeLimitAction(value);
			}
			case Scorm12DataType.CMIInteractionType: {
				return isScorm12InteractionType(value);
			}
			case Scorm12DataType.CMIInteractionResult: {
				return isScorm12InteractionResult(value);
			}
			case Scorm12DataType.CMIFeedback: {
				return true;
			}
			default: {
				return assertNever(type);
			}
		}
	}

	/**
	 * Set a Scorm model value by key
	 * @param key the Scorm model key, e.g. 'cmi.suspend_data'
	 * @param value the value to set
	 */
	public setValue(key: string, value: string) {
		const prop = findPropForKey(key);
		if (prop === undefined) {
			throw new Scorm12Error(Scorm12ErrorCode.INVALID_ARGUMENT);
		}

		if ('propType' in prop && (prop.propType === 'const' || prop.propType === 'count')) {
			throw new Scorm12Error(Scorm12ErrorCode.ELEMENT_IS_KEYWORD);
		}

		if (prop.access === ScormPropertyAccess.ReadOnly) {
			throw new Scorm12Error(Scorm12ErrorCode.ELEMENT_IS_READ_ONLY);
		}

		if (!this.validateDataType(prop.dataType, value)) {
			throw new Scorm12Error(
				Scorm12ErrorCode.INCORRECT_DATA_TYPE,
				`Invalid data type for key ${key}, type ${prop.dataType}, value: ${value}`,
			);
		}

		this.data.set(key, value);
	}

	private getCount(key: string): number {
		// e.g. key = 'cmi.objectives._count'

		// get the subKey: e.g. 'cmi.objectives.'
		const subKey = key.substring(0, key.length - 6);

		// find any data keys in the map that start with the sub key
		let max = -1;
		for (const dataKey of this.data.keys()) {
			if (dataKey.startsWith(subKey)) {
				// this data key starts with the sub key - e.g. 'cmi.objectives.3.id'

				// get the numeric index that comes after the subKey - e.g. '3.id'

				const dataSubKey = dataKey.substring(subKey.length);
				// make sure it matches what we expect
				const match = dataSubKey.match(/^(\d+)\./);
				if (match) {
					const index = Number(match[1]);
					if (index > max) {
						max = index;
					}
				}
			}
		}

		return max + 1;
	}

	/**
	 * Get a Scorm model value by key.
	 * @param key the Scorm model key, e.g. 'cmi.suspend_data'
	 * @returns the value for the key, or '' if no value has been set
	 */
	public getValue(key: string): string {
		const prop = findPropForKey(key);
		if (prop === undefined) {
			throw new Scorm12Error(Scorm12ErrorCode.INVALID_ARGUMENT);
		}

		if (prop.access === ScormPropertyAccess.WriteOnly) {
			throw new Scorm12Error(Scorm12ErrorCode.ELEMENT_IS_WRITE_ONLY);
		}

		if ('propType' in prop && prop.propType === 'const') {
			return prop.constValue;
		}

		if ('propType' in prop && prop.propType === 'count') {
			const count = this.getCount(key);
			return `${count}`;
		}

		const value = this.data.get(key);
		if (value === undefined) {
			return '';
		} else {
			return value;
		}
	}

	/**
	 * Get a raw value from the data store without doing any validation.
	 */
	public getRawValue(key: string): string | undefined {
		return this.data.get(key);
	}

	/**
	 * Serialise the entire model data to a record. Useful for debugging.
	 */
	public serialise(): Record<string, string> {
		const record: Record<string, string> = {};
		for (const [key, value] of this.data.entries()) {
			record[key] = value;
		}
		return record;
	}
}
