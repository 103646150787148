import { FormFieldInfo, getFormValidationErrors } from './formValidation';

/**
 * Toggle visibility of professional fields based on user type (student or non-ICL).
 */
const registrationForm = () => {
	return {
		$refs: {} as {
			radioContainer: HTMLDivElement;
			professionalContainer: HTMLDivElement;
			form: HTMLFormElement;
			errors: HTMLDivElement;
		},

		invalidFields: [] as FormFieldInfo[],
		submissionAttempted: false,
		isFormValid: false,

		get isErrorsVisible() {
			return this.submissionAttempted && !this.isFormValid;
		},

		init() {
			const { radioContainer } = this.$refs;
			const radios = radioContainer.querySelectorAll<HTMLInputElement>('input[type="radio"]');
			radios.forEach((r) => r.addEventListener('change', this.onChangeType.bind(this)));

			const nonIclRadio = Array.from(radios).find((r) => r.value === 'nonIcl');
			this.toggleProfessionalFields(nonIclRadio!.checked);
		},
		onChangeType(e: Event) {
			const input = e.currentTarget as HTMLInputElement;
			this.toggleProfessionalFields(input.value === 'nonIcl');
		},
		toggleProfessionalFields(show: boolean) {
			const { professionalContainer } = this.$refs;
			const inputs = professionalContainer.querySelectorAll('textarea,select');
			if (show) {
				professionalContainer.classList.remove('hidden');
				inputs.forEach((input) => {
					input.setAttribute('required', 'required');
					input.setAttribute('aria-required', 'true');
				});
			} else {
				professionalContainer.classList.add('hidden');
				inputs.forEach((input) => {
					input.removeAttribute('required');
					input.removeAttribute('aria-required');
				});
			}
		},

		checkValid() {
			const { form } = this.$refs;
			if (!form) return false;

			this.invalidFields = getFormValidationErrors(form);
			this.isFormValid = this.invalidFields.length === 0;
		},

		handleValidation(e: Event) {
			this.submissionAttempted = true;
			this.checkValid();

			if (!this.isFormValid) {
				e.stopImmediatePropagation();
			}
		},
	};
};

export default registrationForm;
