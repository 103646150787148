/**
 * QuizData returned from the backend.
 * See Quiz#getUserProgressData().
 */
export type QuizData = {
	CompletedQuestions: number;
	TotalQuestions: number;
	MistakeCount: number;
	Question?: QuizQuestionData;
};

export type QuizQuestionData = {
	ID: string;
	QuestionNumber: number;
	Title: string;
	HasMultipleAnswers: boolean;
	Answers: QuizQuestionAnswerData[];
	SubmitLink: string;
};

export type QuizQuestionAnswerData = {
	ID: string;
	Title: string;
};

export type QuizDataWithSubmittedAnswer = QuizData & {
	Answer: {
		QuestionID: string;
		IsCorrect: boolean;
		AdditionalAnswers?: boolean;
	};
	NextLink?: string;
	NextLinkLabel?: string;
};

type QuizComponentState = {
	data: QuizData | undefined;
	selection: string[];
	errorMessage: string | undefined;
	mistakeCount: number;
	answerResult: boolean | undefined;
	nextQuestionData: QuizDataWithSubmittedAnswer | undefined;
	additionalAnswers: boolean | undefined;
	quizComplete: boolean;
	numQuestions: number;
	saving: boolean;
	isSubmitDisabled: boolean;
	submitAnswer: () => Promise<void>;
	isSelected: (answerId: string) => boolean;
	onClick: (answerId: string) => void;
	next: () => void;
	success: () => void;
};

import { AlpineComponent } from 'alpinejs';
import confetti from 'canvas-confetti';

const quiz = (data: QuizData | undefined = undefined): AlpineComponent<QuizComponentState> => {
	return {
		data,
		selection: [] as string[],
		errorMessage: undefined as string | undefined,
		mistakeCount: data?.MistakeCount || 0,
		answerResult: undefined as boolean | undefined,
		nextQuestionData: undefined as QuizDataWithSubmittedAnswer | undefined,
		additionalAnswers: undefined as boolean | undefined,
		quizComplete: false,
		numQuestions: data?.TotalQuestions || 0,
		saving: false,
		get isSubmitDisabled() {
			return this.selection.length === 0 || this.saving || this.answerResult === false;
		},

		async submitAnswer() {
			if (!this.data?.Question || this.isSubmitDisabled) return;

			try {
				this.errorMessage = undefined;
				this.saving = true;

				const submitData = {
					questionId: this.data.Question.ID,
					answer: Array.isArray(this.selection) ? this.selection : [this.selection],
				};

				const rsp = await fetch(this.data.Question.SubmitLink, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(submitData),
				});
				if (!rsp.ok) {
					throw new Error(`Error submitting answer: ${rsp.statusText}`);
				}

				const newData = (await rsp.json()) as QuizDataWithSubmittedAnswer;
				this.mistakeCount = newData.MistakeCount;
				this.answerResult = newData.Answer.IsCorrect;
				if (newData.Answer.IsCorrect) {
					this.nextQuestionData = newData;
					this.additionalAnswers = false;
				} else {
					this.additionalAnswers = newData.Answer.AdditionalAnswers;
				}
				this.saving = false;
			} catch (err) {
				this.errorMessage = `${err}`;
			}
		},

		isSelected(answerId: string) {
			return this.selection.includes(answerId);
		},

		onClick(answerId: string) {
			if (!this.data?.Question) return;
			this.answerResult = undefined;
			this.additionalAnswers = undefined;
			const idx = this.selection.indexOf(answerId);
			if (this.data.Question.HasMultipleAnswers) {
				if (idx < 0) {
					this.selection = [...this.selection, answerId];
				} else {
					const newSelection = this.selection.slice();
					newSelection.splice(idx, 1);
					this.selection = newSelection;
				}
			} else {
				if (idx < 0) {
					this.selection = [answerId];
				} else {
					this.selection = [];
				}
			}
		},

		next() {
			this.quizComplete = !this.nextQuestionData?.Question;
			this.selection = [];
			this.data = this.nextQuestionData;
			this.answerResult = undefined;
			this.errorMessage = undefined;
			this.nextQuestionData = undefined;
			this.additionalAnswers = false;
			if (!this.quizComplete) {
				this.$focus.focus(this.$refs.questionHeading);
			}
		},

		success() {
			const canvas = document.createElement('canvas');
			canvas.classList.add('absolute', 'inset-0', 'h-full', 'w-full', 'pointer-events-none');
			this.$el.appendChild(canvas);

			const confettiObject = confetti.create(canvas, {
				resize: true,
				useWorker: true,
			});

			const count = 200;
			const defaults = {
				origin: { y: 0.7 },
				disableForReducedMotion: true,
			};

			function fire(particleRatio: number, opts: confetti.Options | undefined) {
				confettiObject({
					...defaults,
					...opts,
					particleCount: Math.floor(count * particleRatio),
				});
			}

			// Add delay
			setTimeout(() => {
				fire(0.25, {
					spread: 26,
					startVelocity: 55,
				});
				fire(0.2, {
					spread: 60,
				});
				fire(0.35, {
					spread: 100,
					decay: 0.91,
					scalar: 0.8,
				});
				fire(0.1, {
					spread: 120,
					startVelocity: 25,
					decay: 0.92,
					scalar: 1.2,
				});
				fire(0.1, {
					spread: 120,
					startVelocity: 45,
				});
			}, 1500);
		},
	};
};

export default quiz;
